import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../contexts/StoreContext";
import { Button, Tag, View, Avatar } from "../../components";
import "./houses.scss";
import { useHistory } from "react-router-dom";
import { Color } from "../../types";
import { ModalContext } from "../../contexts/ModalContext";
import { ModalAddApplicationQuiz } from "../../modals/ModalAddApplicationQuiz/ModalAddApplicationQuiz";
import firebase from "firebase";
import { LoaderContext } from "../../contexts/LoaderContext";
import { ToastContext } from "../../contexts/ToastContext";
import { Loader } from "../../components/Loader/Loader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ModalAddIdol } from "../../modals/ModalAddIdol/ModalAddIdol";
import { ModalEditIdol } from "../../modals/ModalEditIdol/ModalEditIdol";
import { ModalConfirmation } from "../../modals/ModalConfirmation/ModalConfirmation";
import moment from "moment";
import { ModalGallery } from "../../modals/ModalGallery/ModalGallery";
import { ModalPictures } from "../../modals/ModalPictures/ModalPictures";

function Houses() {
  const storeContext = useContext(StoreContext);
  const modalContext = useContext(ModalContext);
  const toastContext = useContext(ToastContext);
  const loaderContext = useContext(LoaderContext);
  const history = useHistory();

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toastContext.open({
        color: Color.success,
        message: "Identifiant " + text + " copié!",
      });
    } catch (err: any) {
      toastContext.open({
        color: Color.danger,
        message: "Erreur: " + err.message,
      });
    }
  };

  const deleteHouse = async (house: any) => {
    try {
      loaderContext.open(<Loader />);
      await firebase
        .firestore()
        .collection("houses")
        .doc(house?.houseId)
        .delete();
      loaderContext.close();
      toastContext.open({
        color: Color.success,
        message: "Le logement a été supprimé avec succès",
      });
      modalContext.close();
    } catch (error) {
      toastContext.open({
        color: Color.danger,
        message: "Erreur lors de la suppression du logement:",
      });
      modalContext.close();
    }
  };

  console.log("houses List: ", storeContext?.housesList);
  return (
    <View title="Mes logements">
      <div className="doctors__section">
        <div className="row ai-center jc-flex-end margin-bottom">
          <Button
            onClick={() => history.push("/houses/create")}
            color={Color.primary}
          >
            Ajouter un logement
          </Button>
        </div>

        <div className="col">
          {storeContext?.housesList
            ?.sort((a: any, b: any) => b?.creationDate - a?.creationDate)
            ?.map((el: any, index: number) => {
              return (
                <div
                  onClick={() => history.push("/houses/house/" + el?.houseId)}
                  key={el?.houseId + index}
                  className="table-body-row row ai-center flex-1"
                >
                  <div className="col ai-flex-start flex-1">
                    <div className="flex row ai-center">
                      <p className="centers__center-row__label">
                        {el?.displayName}
                      </p>
                      {el?.rent && (
                        <p className="margin-left">{el?.rent + "€"}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex row ai-center">
                    <Tag
                      className="margin-right margin-left pointer"
                      color={
                        !!el?.assignedTenant ? Color.success : Color.danger
                      }
                      label={!!el?.assignedTenant ? "Loué" : "Non loué"}
                    />
                    <Tag
                      className="margin-right margin-left pointer"
                      color={Color.danger}
                      label="Supprimer"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        modalContext.open(
                          <ModalConfirmation
                            title="Are you sure ?"
                            callback={() => deleteHouse(el)}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </View>
  );
}

export default Houses;
