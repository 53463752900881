
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Dropdown } from '../../../Dropdown/Dropdown';
import { Input } from '../Input';
import "./Select.scss"

type OptionSelect = {
    label?: string,
    value: any
}
type SelectProps = {
    onChange: (value: any) => void,
    value: any[],
    options: OptionSelect[] | (() => OptionSelect[]),
    multiple?: boolean,
    placeholder?: string
}

type OptionProps = {
    child?: any,
    label?: string,
    onClick: () => void,
    className?: string
}

const Option = ({ child, label, onClick, className }: OptionProps) => {
    return <div className={`option ${className}`} onClick={onClick}>
        {child && <div>{child}</div>}
        <div>{label}</div>
    </div>
}
export const Select: FunctionComponent<SelectProps> = ({
    onChange,
    value = [],
    options,
    multiple,
    placeholder
}) => {
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState("")

    const _options = useAsyncCallback(async () =>
        typeof options === "function" ? await options() : options
    )
    const data = (_options?.result as OptionSelect[])

    useEffect(() => {
        _options.execute()
    }, [])

    const onOptionClick = (clickedValue: any, index: number) => {
        let newValue = [...value]
        if (!multiple)
            newValue = [clickedValue]
        else {
            const inValueindex = newValue?.findIndex(el => el === clickedValue)
            if (inValueindex < 0)
                newValue.push(clickedValue)
            else
                newValue.splice(inValueindex, 1)
        }
        onChange(newValue)
        if (!multiple)
            setOpen(false)
    }


    const renderValue = () => {
        if (value.length === 0)
            return <p className="placeholder">{placeholder}</p>
        else if (value.length >= 1) {
            const elem = _options?.result?.find(el => el.value === value[0])
            return `${elem?.label || value[0]}` + (value?.length > 1 ? ` & ${value?.length - 1}` : '')
        }
    }

    return <button
        className="input input-select input-border"
        type="button"
        onClick={() => setOpen(true)}
    >
        <div className="input-select__input-container">
            {renderValue()}
        </div>
        <Dropdown
            visible={open}
            onClickOutside={() => setOpen(false)}
        >
            <div className="input-select__dropdown">
                {
                    data?.length > 10
                    && <div className="input-select__dropdown__search">
                        <Input.Text value={search} onChange={setSearch} placeholder="Rechercher" />
                    </div>
                }
                <div className="input-select__dropdown__scrollview">
                    {data?.filter((opt) => opt?.label?.toLowerCase().includes(search.toLowerCase())).map((option: any, index: number) => <Option
                        onClick={() => onOptionClick(option.value, index)}
                        className={value?.includes(option.value) ? 'selected' : ''}
                        {...option}
                    />)}
                </div>
            </div>
        </Dropdown>
    </button>
}
