import firebase from "firebase";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

export const useOnSnapshotTenants = () => {
  const authContext = useContext(AuthContext);
  const [error, setError] = useState(null as any);
  const [loading, setLoading] = useState(true);
  const [tenants, setTenants] = useState([] as any);

  useEffect(() => {
    if (authContext?.user?.uid) {
      firebase
        .firestore()
        .collection("tenants")
        .where("userId", "==", authContext?.user?.uid)
        .onSnapshot(
          (snapshot) => {
            const newTenants = [] as any;
            snapshot.forEach((doc) => {
              newTenants.push({ tenantId: doc.id, ...doc.data() });
            });
            setLoading(false);
            setTenants(newTenants);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }, [authContext?.user]);
  return tenants;
};
