import React, { useEffect } from 'react';
import './App.scss';
import AppRouter from './AppRouter';
import { AppConfig } from "./AppConfig";
import firebase from "firebase";
import { StoreProvider } from "./contexts/StoreContext";
import { ToastProvider } from "./contexts/ToastContext";
import { AuthProvider } from "./contexts/AuthContext";
import { ModalProvider } from "./contexts/ModalContext";
import { LodaerProvider } from './contexts/LoaderContext';

firebase.initializeApp(AppConfig.firebaseConfig);

function App() {
    return (
        <LodaerProvider>
            <ToastProvider>
                <AuthProvider>
                    <StoreProvider>
                        <ModalProvider>
                            <AppRouter />
                        </ModalProvider>
                    </StoreProvider>
                </AuthProvider>
            </ToastProvider>
        </LodaerProvider>
    );
}

export default App;
