import { Radio } from "./Radio/Radio";
import { Text } from "./Text/Text";
import { Select } from "./Select/Select";
import { File } from "./File/File";
import { Date } from "./Date/Date";
import "./Input.scss";

export const Input = {
  Radio,
  Text,
  Select,
  File,
  Date,
};
