import { AuthContext } from "../../contexts/AuthContext";
import { useForm } from "../../hooks/useForm";
import { useContext } from "react";
import { StoreContext } from "../../contexts/StoreContext";
import { Button, Field, Input } from "../../components";
import { Color } from "../../types";
import { Link, useHistory } from "react-router-dom";
import { ViewWithForm } from "../../components/ViewWithForm/ViewWithForm";
import './signin.scss';
import { ToastContext } from "../../contexts/ToastContext";

const FORM_DATA_DEFAULT = {
    email: "",
    password: ""
}
function SignIn() {
    const authContext = useContext(AuthContext)
    const storeContext = useContext(StoreContext)
    const toastContext = useContext(ToastContext)
    const history = useHistory()
    const form = useForm({
        onSubmit: () => {
            signInWithEmailAndPassword()
        },
        initialData: FORM_DATA_DEFAULT,
        rules: {
            password: [{ required: true, message: "Ce champ est requis" }],
            email: [{ required: true, message: "Ce champ est requis" }]
        }
    })

    const signInWithEmailAndPassword = async () => {
        const { email, password } = form.data
        try {
            await authContext.signInWithEmailAndPassword(email, password)
            history.push("/")

        } catch (err:any) {
            toastContext.open({ color: Color.danger, message: err.message })
            console.log(err.message)
        }
    }

    return (
        <ViewWithForm
            className="signin"
            childrenForm={
                <>
                    <div className="col jc-center flex-1">
                        <h1 className="signin__title">Connectez-vous</h1>
                        <form className="signin__form">
                            <Field
                                label="Adresse email"
                                status={form.status?.email}
                            >
                                <Input.Text
                                    placeholder="Ex: adresse@email.com"
                                    onChange={(value) => form.onChange("email", value)}
                                    value={form.data.email}
                                />
                            </Field>
                            <Field
                                label="Mot de passe"
                                status={form.status?.password}
                            >
                                <Input.Text
                                    placeholder="Ex: X8jvfc-4Bzert"
                                    onChange={(value: any) => form.onChange("password", value)}
                                    value={form.data.password}
                                    type="password"
                                />
                            </Field>
                            <div className="margin-bottom">
                                <a className="signin__forget-password">Mot de passe oublié ?</a>
                            </div>
                            <div className="row">
                                <Button
                                    className="flex-1 ai-center jc-center"
                                    color={Color.primary}
                                    onClick={form.submit}
                                    loading={form.loading}
                                > Se connecter </Button>
                            </div>
                        </form>
                    </div>
                </>
            }
        />

    );

}

export default SignIn
