export enum Color {
  primary = "primary",
  default = "default",
  secondary = "secondary",
  tertiary = "tertiary",
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info",
  white = "white",
  black = "black"
}


export enum ModalTypes {
  validation = "validation"
}

export enum States {
  waiting_for_confirmation = "WAITING_FOR_CONFIRMATION",
  waiting_for_payment_method = "WAITING_FOR_PAYMENT_METHOD",
  available = "AVAILABLE"
}


export interface Toast {
  color: Color,
  message?: string,
  time?: number,
  children?: any
}


export enum Icons {
  Add = "add",
  AlarmClock = "alarm-clock",
  AlignCenter = "align-center",
  AlignJustify = "align-justify",
  AlignLeft = "align-left",
  AlignRight = "align-right",
  Ambulance = "ambulance",
  AngleDoubleLeft = "angle-double-left",
  AngleDoubleRight = "angle-double-right",
  AngleDoubleSmallLeft = "angle-double-small-left",
  AngleDoubleSmallRight = "angle-double-small-right",
  AngleDown = "angle-down",
  AngleLeft = "angle-left",
  AngleRight = "angle-right",
  AngleSmallDown = "angle-small-down",
  AngleSmallLeft = "angle-small-left",
  AngleSmallRight = "angle-small-right",
  AngleSmallUp = "angle-small-up",
  AngleUp = "angle-up",
  AppsAdd = "apps-add",
  AppsDelete = "apps-delete",
  AppsSort = "apps-sort",
  Apps = "apps",
  Archive = "archive",
  ArrowDown = "arrow-down",
  ArrowLeft = "arrow-left",
  ArrowRight = "arrow-right",
  ArrowSmallDown = "arrow-small-down",
  ArrowSmallLeft = "arrow-small-left",
  ArrowSmallRight = "arrow-small-right",
  ArrowSmallUp = "arrow-small-up",
  ArrowUp = "arrow-up",
  Asterisk = "asterisk",
  Backpack = "backpack",
  Badge = "badge",
  Ban = "ban",
  BandAid = "band-aid",
  Bank = "bank",
  BarberShop = "barber-shop",
  Baseball = "baseball",
  Basketball = "basketball",
  BellRing = "bell-ring",
  BellSchool = "bell-school",
  Bell = "bell",
  Billiard = "billiard",
  Bold = "bold",
  BookAlt = "book-alt",
  Book = "book",
  Bookmark = "bookmark",
  Bowling = "bowling",
  BoxAlt = "box-alt",
  Box = "box",
  Briefcase = "briefcase",
  Broom = "broom",
  Browser = "browser",
  Brush = "brush",
  Bug = "bug",
  Building = "building",
  Bulb = "bulb",
  Calculator = "calculator",
  Calendar = "calendar",
  Camera = "camera",
  CaretDown = "caret-down",
  CaretLeft = "caret-left",
  CaretRight = "caret-right",
  CaretUp = "caret-up",
  Check = "check",
  Checkbox = "checkbox",
  Chess = "chess",
  CircleSmall = "circle-small",
  Circle = "circle",
  Clip = "clip",
  Clock = "clock",
  CloudCheck = "cloud-check",
  CloudDisabled = "cloud-disabled",
  CloudDownload = "cloud-download",
  CloudShare = "cloud-share",
  CloudUpload = "cloud-upload",
  Cloud = "cloud",
  CommentAlt = "comment-alt",
  Comment = "comment",
  CompressAlt = "compress-alt",
  Compress = "compress",
  Computer = "computer",
  Cookie = "cookie",
  CopyAlt = "copy-alt",
  Copy = "copy",
  Copyright = "copyright",
  Cream = "cream",
  CreditCard = "credit-card",
  CrossCircle = "cross-circle",
  CrossSmall = "cross-small",
  Cross = "cross",
  Crown = "crown",
  CursorFinger = "cursor-finger",
  CursorPlus = "cursor-plus",
  CursorTextAlt = "cursor-text-alt",
  CursorText = "cursor-text",
  Cursor = "cursor",
  Dart = "dart",
  Dashboard = "dashboard",
  DataTransfer = "data-transfer",
  Database = "database",
  Delete = "delete",
  Diamond = "diamond",
  Dice = "dice",
  Diploma = "diploma",
  Disk = "disk",
  Doctor = "doctor",
  DocumentSigned = "document-signed",
  Document = "document",
  Dollar = "dollar",
  Download = "download",
  Duplicate = "duplicate",
  ELearning = "e-learning",
  Earnings = "earnings",
  EditAlt = "edit-alt",
  Edit = "edit",
  Envelope = "envelope",
  Euro = "euro",
  Exclamation = "exclamation",
  Expand = "expand",
  EyeCrossed = "eye-crossed",
  EyeDropper = "eye-dropper",
  Eye = "eye",
  Feather = "feather",
  FileAdd = "file-add",
  FileAi = "file-ai",
  FileCheck = "file-check",
  FileDelete = "file-delete",
  FileEps = "file-eps",
  FileGif = "file-gif",
  FileMusic = "file-music",
  FilePsd = "file-psd",
  File = "file",
  Fill = "fill",
  Film = "film",
  Filter = "filter",
  Fingerprint = "fingerprint",
  Flag = "flag",
  Flame = "flame",
  FlipHorizontal = "flip-horizontal",
  FolderAdd = "folder-add",
  Folder = "folder",
  Following = "following",
  Football = "football",
  Form = "form",
  Forward = "forward",
  Ftp = "ftp",
  Gallery = "gallery",
  Glasses = "glasses",
  GlobeAlt = "globe-alt",
  Globe = "globe",
  Golf = "golf",
  GraduationCap = "graduation-cap",
  GraphicTablet = "graphic-tablet",
  GridAlt = "grid-alt",
  Grid = "grid",
  Gym = "gym",
  Headphones = "headphones",
  Headset = "headset",
  Heart = "heart",
  Home = "home",
  HourglassEnd = "hourglass-end",
  Hourglass = "hourglass",
  IceSkate = "ice-skate",
  IdBadge = "id-badge",
  Inbox = "inbox",
  Incognito = "incognito",
  Indent = "indent",
  Infinity = "infinity",
  Info = "info",
  Interactive = "interactive",
  Interlining = "interlining",
  Interrogation = "interrogation",
  Italic = "italic",
  Jpg = "jpg",
  Key = "key",
  Keyboard = "keyboard",
  Label = "label",
  Laptop = "laptop",
  Lasso = "lasso",
  Layers = "layers",
  LayoutFluid = "layout-fluid",
  LetterCase = "letter-case",
  LifeRing = "life-ring",
  LineWidth = "line-width",
  Link = "link",
  Lipstick = "lipstick",
  ListCheck = "list-check",
  List = "list",
  LocationAlt = "location-alt",
  LockAlt = "lock-alt",
  Lock = "lock",
  MagicWand = "magic-wand",
  MakeupBrush = "makeup-brush",
  MarkerTime = "marker-time",
  Marker = "marker",
  Medicine = "medicine",
  Megaphone = "megaphone",
  MenuBurger = "menu-burger",
  MenuDotsVertical = "menu-dots-vertical",
  MenuDots = "menu-dots",
  Microphone = "microphone",
  MinusSmall = "minus-small",
  Minus = "minus",
  Mobile = "mobile",
  ModeLandscape = "mode-landscape",
  ModePortrait = "mode-portrait",
  Money = "money",
  Mouse = "mouse",
  MusicAlt = "music-alt",
  Music = "music",
  NetworkCloud = "network-cloud",
  Network = "network",
  Notebook = "notebook",
  Opacity = "opacity",
  Package = "package",
  PaintBrush = "paint-brush",
  Palette = "palette",
  PaperPlane = "paper-plane",
  Password = "password",
  Pause = "pause",
  Pencil = "pencil",
  Pharmacy = "pharmacy",
  Physics = "physics",
  Picture = "picture",
  PingPong = "ping-pong",
  PlayAlt = "play-alt",
  Play = "play",
  PlayingCards = "playing-cards",
  PlusSmall = "plus-small",
  Plus = "plus",
  PokerChip = "poker-chip",
  Portrait = "portrait",
  Pound = "pound",
  Power = "power",
  Presentation = "presentation",
  Print = "print",
  Protractor = "protractor",
  Pulse = "pulse",
  QuoteRight = "quote-right",
  Rec = "rec",
  Receipt = "receipt",
  RectangleHorizontal = "rectangle-horizontal",
  RectanglePanoramic = "rectangle-panoramic",
  RectangleVertical = "rectangle-vertical",
  Redo = "redo",
  Reflect = "reflect",
  Refresh = "refresh",
  Resize = "resize",
  Resources = "resources",
  Rewind = "rewind",
  Rocket = "rocket",
  RotateRight = "rotate-right",
  Rugby = "rugby",
  Scale = "scale",
  SchoolBus = "school-bus",
  School = "school",
  Scissors = "scissors",
  Screen = "screen",
  SearchAlt = "search-alt",
  Search = "search",
  SettingsSliders = "settings-sliders",
  Settings = "settings",
  Share = "share",
  ShieldCheck = "shield-check",
  ShieldExclamation = "shield-exclamation",
  ShieldInterrogation = "shield-interrogation",
  ShieldPlus = "shield-plus",
  Shield = "shield",
  Shop = "shop",
  ShoppingBagAdd = "shopping-bag-add",
  ShoppingBag = "shopping-bag",
  ShoppingCartAdd = "shopping-cart-add",
  ShoppingCartCheck = "shopping-cart-check",
  ShoppingCart = "shopping-cart",
  Shuffle = "shuffle",
  SignIn = "sign-in",
  SignOut = "sign-out",
  SignalAlt1 = "signal-alt-1",
  SignalAlt2 = "signal-alt-2",
  SignalAlt = "signal-alt",
  Skateboard = "skateboard",
  Smartphone = "smartphone",
  Soap = "soap",
  Spa = "spa",
  Speaker = "speaker",
  SpinnerAlt = "spinner-alt",
  Spinner = "spinner",
  SquareRoot = "square-root",
  Square = "square",
  Star = "star",
  Stats = "stats",
  Stethoscope = "stethoscope",
  Sticker = "sticker",
  Stop = "stop",
  Stopwatch = "stopwatch",
  Subtitles = "subtitles",
  Surfing = "surfing",
  Sword = "sword",
  Syringe = "syringe",
  Tablet = "tablet",
  Target = "target",
  Tennis = "tennis",
  TestTube = "test-tube",
  Test = "test",
  TextCheck = "text-check",
  Text = "text",
  ThumbsDown = "thumbs-down",
  ThumbsUp = "thumbs-up",
  Ticket = "ticket",
  TimeAdd = "time-add",
  TimeCheck = "time-check",
  TimeDelete = "time-delete",
  TimeFast = "time-fast",
  TimeForwardSixty = "time-forward-sixty",
  TimeForwardTen = "time-forward-ten",
  TimeForward = "time-forward",
  TimeHalfPast = "time-half-past",
  TimeOclock = "time-oclock",
  TimePast = "time-past",
  TimeQuarterPast = "time-quarter-past",
  TimeQuarterTo = "time-quarter-to",
  TimeTwentyFour = "time-twenty-four",
  ToolCrop = "tool-crop",
  ToolMarquee = "tool-marquee",
  Tooth = "tooth",
  Transform = "transform",
  Trash = "trash",
  Treatment = "treatment",
  Trophy = "trophy",
  Umbrella = "umbrella",
  Underline = "underline",
  Undo = "undo",
  Unlock = "unlock",
  Upload = "upload",
  UsbDrive = "usb-drive",
  UserAdd = "user-add",
  UserDelete = "user-delete",
  UserRemove = "user-remove",
  UserTime = "user-time",
  User = "user",
  Users = "users",
  VectorAlt = "vector-alt",
  Vector = "vector",
  VideoCamera = "video-camera",
  Volleyball = "volleyball",
  Volume = "volume",
  WifiAlt = "wifi-alt",
  World = "world",
  yen = "yen",
  ZoomIn = "zoom-in",
  ZoomOut = "zoom-out"
}
