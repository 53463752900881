import React, { useState } from "react";
import { Toast } from "../components";
import { Color, Toast as ToastType } from "../types";

let ToastContext = React.createContext({
    open: (value: ToastType) => { },
    close: (index: number) => { }
});


let ToastProvider = ({ children }: any) => {
    const [toasts, setToasts] = useState([] as Array<any>)

    const open = ({ color = Color.default, message, children }: ToastType) => {
        const newToasts = [...toasts]
        newToasts.push({ color, message, children })
        setToasts(newToasts)
    }

    const close = (index: number) => {
        const newToasts = [...toasts]
        newToasts.splice(index, 1)
        setToasts(newToasts)
    }

    return (
        <ToastContext.Provider value={{
            open,
            close
        }}>
            <div className="toast-wrapper">
                {toasts?.map((toast, index) => <Toast key={Math.random()} {...toast} index={index} />)}
            </div>
            {children}
        </ToastContext.Provider>
    );
};

export { ToastContext, ToastProvider };
