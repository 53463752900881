
import React, { FunctionComponent } from 'react';
import { Color } from '../../../../types';
import "./Tag.scss"

type TagProps = {
    children?: any,
    color?: Color,
    className?: string,
    label?: string,
    onClick?: any
}

export const Tag = ({
    children,
    color,
    className,
    label,
    onClick
}: TagProps) => <span
    className={`tag tag--${color} ${className}`}
    onClick={onClick}
>
        {label && <span className="tag__label">{label}</span>}
        <div className="row ai-center">{children}</div>
    </span>