import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../contexts/StoreContext";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../contexts/ToastContext";
import { Avatar, Button, Field, Input, Tag, View } from "../../components";
import { Color } from "../../types";
import { Loader } from "../../components/Loader/Loader";
import { LoaderContext } from "../../contexts/LoaderContext";
import firebase from "firebase";
import { Document } from "../../components/Document/Document";
import "./createTenant.scss";

import moment from "moment";
import { ModalContext } from "../../contexts/ModalContext";
import { ModalEditIdol } from "../../modals/ModalEditIdol/ModalEditIdol";
import { SummaryElement } from "../../components/SummaryElement/SummaryElement";
import { useForm } from "../../hooks/useForm";
import { ModalGallery } from "../../modals/ModalGallery/ModalGallery";
import DatePicker from "react-date-picker";

const DATE_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

function CreateTenant() {
  const history = useHistory();
  const storeContext = useContext(StoreContext);
  const toastContext = useContext(ToastContext);
  const loaderContext = useContext(LoaderContext);
  const modalContext = useContext(ModalContext);
  const params = useParams() as any;

  const form = useForm({
    onSubmit: async (e: any) => {
      try {
        loaderContext.open(<Loader />);
        await firebase
          .firestore()
          .collection("tenants")
          .add({
            name: e?.name || "",
            email: e?.email || "",
            knownMonthlySalary: e?.knownMonthlySalary || "",
            phoneNumber: e?.phoneNumber || "",
            creationDate: moment.now(),
            dateOfBirth: new Date(e?.dateOfBirth).getTime() || "",
          });
        loaderContext.close();
        toastContext.open({
          color: Color.success,
          message: "Le locataire a été ajouté avec succès.",
        });
        history.push("/tenants");
      } catch (error: any) {
        loaderContext.close();
        toastContext.open({ color: Color.danger, message: error.message });
      }
    },
    rules: {
      name: [{ required: true, message: "Ce champ est requis" }],
      email: [{ required: true, message: "Ce champ est requis" }],
      phoneNumber: [{ required: true, message: "Ce champ est requis" }],
    },
  });

  return (
    <View title="Création d'un locataire">
      <div className="applications__section">
        <div>
          <form className="signin__form">
            <Field required label="Nom" status={form.status?.name}>
              <Input.Text
                placeholder="Entrez le nom ..."
                onChange={(value: any) => form.onChange("name", value)}
                value={form.data.name}
              />
            </Field>
            <Field required label="Mail" status={form.status?.email}>
              <Input.Text
                placeholder="Entrez le mail..."
                onChange={(value: any) => form.onChange("email", value)}
                value={form.data.email}
              />
            </Field>
            <Field
              required
              label="Numéro de téléphone"
              status={form.status?.phoneNumber}
            >
              <Input.Text
                placeholder="Entrez le numéro de téléphone..."
                onChange={(value: any) => form.onChange("phoneNumber", value)}
                value={form.data.phoneNumber}
              />
            </Field>

            <Field label="Birth Date" status={form.status?.dateOfBirth}>
              <Input.Text
                placeholder="Ex: 2020-02-21"
                onChange={(value: any) => form.onChange("dateOfBirth", value)}
                value={form.data.dateOfBirth}
              />
            </Field>

            <Field
              label="Salaire mensuel connu"
              status={form.status?.knownMonthlySalary}
            >
              <Input.Text
                placeholder="Entrez le salaire..."
                onChange={(value: any) =>
                  form.onChange("knownMonthlySalary", value)
                }
                value={form.data.knownMonthlySalary}
              />
            </Field>
          </form>
        </div>
        <div>
          <Button color={Color.primary} onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button
            className="margin-left"
            color={Color.warning}
            onClick={form.submit}
          >
            Confirmer
          </Button>
        </div>
      </div>
    </View>
  );
}

export default CreateTenant;
