const type_env = {
  PRODUCTION: "production",
  STAGE: "stage",
  DEVELOP: "develop",
};

const configFirebase = (key: any) => {
  // @ts-ignore
  return {
    [type_env.PRODUCTION]: {
      apiKey: "AIzaSyAyikumAews0d9dVHT_Ts171hu_Z4PPy6o",
      authDomain: "lympeimmo.firebaseapp.com",
      projectId: "lympeimmo",
      storageBucket: "lympeimmo.appspot.com",
      messagingSenderId: "829179967007",
      appId: "1:829179967007:web:a81deb1729a997c6f9472a",
      measurementId: "G-9M36LX5W33",
    },

    [type_env.STAGE]: {
      apiKey: "AIzaSyAyikumAews0d9dVHT_Ts171hu_Z4PPy6o",
      authDomain: "lympeimmo.firebaseapp.com",
      projectId: "lympeimmo",
      storageBucket: "lympeimmo.appspot.com",
      messagingSenderId: "829179967007",
      appId: "1:829179967007:web:a81deb1729a997c6f9472a",
      measurementId: "G-9M36LX5W33",
    },

    [type_env.DEVELOP]: {
      apiKey: "AIzaSyAyikumAews0d9dVHT_Ts171hu_Z4PPy6o",
      authDomain: "lympeimmo.firebaseapp.com",
      projectId: "lympeimmo",
      storageBucket: "lympeimmo.appspot.com",
      messagingSenderId: "829179967007",
      appId: "1:829179967007:web:a81deb1729a997c6f9472a",
      measurementId: "G-9M36LX5W33",
    },
  }[key];
};

export const AppConfig = {
  firebaseConfig: configFirebase(type_env.PRODUCTION),
};
