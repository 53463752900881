
import { useContext } from "react";
import { ModalContext } from "../../../contexts/ModalContext";
import { ModalPictures } from "../../../modals/ModalPictures/ModalPictures";
import "./Image.scss";


interface ImageProps {
    key: any,
    url?: any,
    thumbnail?: any,
    onClick?: any,
    selected?: boolean,
    name?: string
}

const buildName = (name: string) => {
    if (name.length >= 10) {
        return name.substring(0, 9) + "..."
    } else {
        return name
    }
}
export const Image = ({ key, url, thumbnail, onClick, selected, name }: ImageProps) => {
    const modalContext = useContext(ModalContext)
    return <div key={key} className="margin-right" onClick={() => modalContext.open(<ModalPictures imgUrl={!!url ? url : thumbnail} />)}>
        <img className={selected ? "selected gallery" : "gallery"}
            src={thumbnail || url} />
        <p className="file-name">{buildName(name || "")}</p>
    </div>
}

export const ImageSelector = ({ key, url, thumbnail, selected, name }: ImageProps) => {


    return <div key={key} className="margin-right">
        <img className={selected ? "selected gallery" : "gallery"}
            src={thumbnail || url} />
        <p className="file-name">{buildName(name || "")}</p>
    </div>
}



