import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../contexts/StoreContext";
import { Button, Tag, View, Avatar, Field, Input } from "../../components";
import "./settings.scss";
import { useHistory } from "react-router-dom";
import { Color, States } from "../../types";
import { ModalContext } from "../../contexts/ModalContext";
import firebase from "firebase";
import { LoaderContext } from "../../contexts/LoaderContext";
import { ToastContext } from "../../contexts/ToastContext";
import { Loader } from "../../components/Loader/Loader";
import { ModalConfirmation } from "../../modals/ModalConfirmation/ModalConfirmation";
import moment from "moment";
import { ModalPictures } from "../../modals/ModalPictures/ModalPictures";
import { AuthContext } from "../../contexts/AuthContext";
import { SummaryElement } from "../../components/SummaryElement/SummaryElement";
import { useForm } from "../../hooks/useForm";
import SignaturePad from "signature_pad";

function Account() {
  const [tab, setTab] = useState("informations");
  const [signaturePad, setSignaturePad] = useState(null as any);
  const [currentSignature, setCurrentSignature] = useState(null as any);
  const [editSignature, setEditSignature] = useState(false);
  const authContext = useContext(AuthContext);
  const storeContext = useContext(StoreContext);
  const toastContext = useContext(ToastContext);
  const form = useForm({
    onSubmit: () => {},
    rules: {
      newPassword: [{ required: true, message: "Ce champ est requis" }],
      currentPassword: [{ required: true, message: "Ce champ est requis" }],
      newPasswordConfirmation: [
        { required: true, message: "Ce champ est requis" },
        {
          func: () => checkPasswordMatch(),
          message:
            "Le mot de passe et la confirmation doivent êtres identiques",
        },
      ],
    },
  });

  const initSignatureTab = () => {
    const ref = firebase
      .firestore()
      .collection("signatures")
      .doc(authContext?.user?.uid);
    ref.get().then((doc) => {
      if (doc.exists) {
        setCurrentSignature(doc.data()?.image);
        setEditSignature(false);
        setSignaturePad(null);
      } else {
        setCurrentSignature(null);
        setEditSignature(true);
        setTimeout(() => {
          const canvasRef = document.querySelector(
            "canvas"
          ) as HTMLCanvasElement;
          setSignaturePad(new SignaturePad(canvasRef));
        }, 100);
      }
    });
  };

  const editCurrentSignature = () => {
    setEditSignature(true);
    setTimeout(() => {
      const canvasRef = document.querySelector("canvas") as HTMLCanvasElement;
      setSignaturePad(new SignaturePad(canvasRef));
    }, 100);
  };

  const saveSignature = () => {
    console.log("user id: ", authContext?.user?.uid);
    const data = signaturePad.toDataURL();
    firebase
      .firestore()
      .collection("signatures")
      .doc(authContext?.user?.uid)
      .set({ image: data })
      .then(() => {
        toastContext.open({
          color: Color.success,
          message: "Signature sauvegardée avec succès.",
        });
        setEditSignature(false);
        setCurrentSignature(data);
      })
      .catch((err: any) => {
        toastContext.open({
          color: Color.danger,
          message: "Erreur lors de sauvegarde de la signature.",
        });
      });
  };

  const checkPasswordMatch = () => {
    const { newPassword, newPasswordConfirmation } = form.data;
    return newPassword === newPasswordConfirmation;
  };

  const redirectUserToPortal = () => {
    storeContext
      .createStripePortalSession()
      .then((session: any) => {
        window.open(session.data.url);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <View title="Réglages" bodyClassName="account">
      <div className="account__tabs">
        <div
          className={`account__tabs__tab ${
            tab === "informations" ? "active" : ""
          }`}
          onClick={() => setTab("informations")}
        >
          Informations
        </div>
        <div
          className={`account__tabs__tab ${tab === "security" ? "active" : ""}`}
          onClick={() => setTab("security")}
        >
          Sécurité
        </div>
        <div
          className={`account__tabs__tab ${tab === "billing" ? "active" : ""}`}
          onClick={() => setTab("billing")}
        >
          Facturation
        </div>
        <div
          className={`account__tabs__tab ${
            tab === "signature" ? "active" : ""
          }`}
          onClick={() => {
            setTab("signature");
            initSignatureTab();
          }}
        >
          Signature
        </div>
        <div
          className={`account__tabs__tab ${tab === "help" ? "active" : ""}`}
          onClick={() => {
            setTab("help");
          }}
        >
          Aide
        </div>
      </div>
      <div className="account__content">
        {tab === "informations" && (
          <div>
            <h1>Information</h1>
            {authContext?.user?.state === States.waiting_for_payment_method && (
              <p className="info-bubble--danger">
                Veuillez remplir vos coordonnées de paiements.
                <span
                  className="margin-left info-bubble__link"
                  onClick={() => redirectUserToPortal()}
                >
                  Configurer
                </span>
              </p>
            )}
            <h3>Contact</h3>
            <div>
              <SummaryElement
                label="Adresse email"
                value={authContext?.user?.email}
              />
              <SummaryElement
                label="Numéro de téléphone"
                value={authContext?.user?.phoneNumber}
              />
            </div>
          </div>
        )}
        {tab === "security" && (
          <div>
            <h1>Sécurité</h1>
            <form className="signin__form">
              <Field
                label="Mot de passe actuel"
                status={form.status?.currentPassword}
              >
                <Input.Text
                  placeholder="Ex: zegyakznj"
                  onChange={(value) => form.onChange("currentPassword", value)}
                  value={form.data.currentPassword}
                  type="password"
                />
              </Field>
              <Field
                label="Nouveau mot de passe"
                status={form.status?.newPassword}
              >
                <Input.Text
                  placeholder="Ex: X8jvfc-4Bzert"
                  onChange={(value: any) => form.onChange("newPassword", value)}
                  value={form.data.newPassword}
                  type="password"
                />
              </Field>
              <Field
                label="Confirmer le nouveau mot de passe"
                status={form.status?.newPasswordConfirmation}
              >
                <Input.Text
                  placeholder="Ex: X8jvfc-4Bzert"
                  onChange={(value: any) =>
                    form.onChange("newPasswordConfirmation", value)
                  }
                  value={form.data.newPasswordConfirmation}
                  type="password"
                />
              </Field>
              <div className="row">
                <Button
                  className="flex-1 ai-center jc-center"
                  color={Color.primary}
                  onClick={form.submit}
                >
                  Modifier
                </Button>
              </div>
            </form>
          </div>
        )}
        {tab === "billing" && (
          <div>
            <h1>Facturation</h1>

            <Button
              color={Color.primary}
              onClick={() => redirectUserToPortal()}
            >
              {"Dashboard Stripe"}
            </Button>
          </div>
        )}
        {tab === "signature" && (
          <div>
            <h1>Signature</h1>
            {editSignature && (
              <div>
                <canvas width={500} height={150} className="signature" />
                <br />
                <Button
                  color={Color.primary}
                  onClick={() => signaturePad.clear()}
                >
                  Effacer
                </Button>
                <Button color={Color.primary} onClick={() => saveSignature()}>
                  Sauvegarder
                </Button>
                {currentSignature && (
                  <Button
                    color={Color.primary}
                    onClick={() => setEditSignature(false)}
                  >
                    Annuler
                  </Button>
                )}
              </div>
            )}
            {!editSignature && (
              <div>
                <img src={currentSignature} className="signature" />
                <Button
                  color={Color.primary}
                  onClick={() => editCurrentSignature()}
                >
                  Modifier
                </Button>
              </div>
            )}
          </div>
        )}
        {tab === "help" && (
          <div>
            <h1>Aide</h1>
            <p>
              Si vous rencontrez des difficultés, vous pouvez joindre le support
              à l'adresse{" "}
              <span className="help__address">support@lympe.fr</span>
            </p>
            <p>
              Nous tenterons de résoudre vos problèmes dans les meilleurs
              délais.
            </p>
          </div>
        )}
      </div>
    </View>
  );
}

export default Account;
