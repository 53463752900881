import { useContext } from "react"
import ReactDOM from "react-dom"
import { ModalContext } from "../../contexts/ModalContext"
import "./ModalPictures.scss";

interface ModalPicturesProps {
    imgUrl: string
}
export const ModalPictures = ({ imgUrl }: ModalPicturesProps) => {
    const modalContext = useContext(ModalContext)

    const close = () => {
        modalContext.close()
    }
    return ReactDOM.createPortal(
        <div className="preview">
            <button className="preview__close-button" onClick={modalContext.close}>
                <i className="fi-rr-cross"></i>
            </button>
            <img className="preview__img" src={imgUrl} />
        </div>,
        document.querySelector("#modal-root") as HTMLInputElement)

}