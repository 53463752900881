
import React, { FunctionComponent } from 'react';
import "./Radio.scss"

type OptionRadio = {
    label?: string,
    value: any
}
type RadioProps = {
    onChange: (value: any) => void,
    value: any,
    options?: OptionRadio[]
}

export const Radio: FunctionComponent<RadioProps> = ({ onChange, value, options }) => {
    return <div>
        {
            options?.map((option) => <button
                type="button"
                className="input input-radio input-border"
                onClick={() => onChange(value === option.value ? null : option.value)}
            >
                <div className="input-radio__checkbox">
                    <div className={`input-radio__checkbox__check ${option.value === value ? 'active' : ''}`} />
                </div>
                <div className="input-radio__label">
                    {option.label}
                </div>
            </button>)
        }
    </div>
}