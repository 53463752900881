import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../contexts/StoreContext";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../contexts/ToastContext";
import { Avatar, Button, Tag, View } from "../../components";
import { Color } from "../../types";
import { Loader } from "../../components/Loader/Loader";
import { LoaderContext } from "../../contexts/LoaderContext";
import firebase from "firebase";
import "./tenant.scss";

import moment from "moment";
import { ModalContext } from "../../contexts/ModalContext";
import { ModalCreateQuiz } from "../../modals/ModalCreateQuiz/ModalCreateQuiz";
import { Toggle } from "../../components/Form/Toggle/Toggle";
import { useAsyncCallback } from "react-async-hook";
import { ModalCreateQuestion } from "../../modals/ModalCreateQuestion/ModalCreateQuestion";
import { TranscoQuestion } from "../../models/models";
import { Collapse } from "../../components/Collapse/Collapse";
import { ModalConfirmation } from "../../modals/ModalConfirmation/ModalConfirmation";
import { ModalPictures } from "../../modals/ModalPictures/ModalPictures";
import { ModalEditQuiz } from "../../modals/ModalEditQuiz/ModalEditQuiz";
import { ModalEditIdol } from "../../modals/ModalEditIdol/ModalEditIdol";
import { SummaryElement } from "../../components/SummaryElement/SummaryElement";

function Tenant() {
  const history = useHistory();
  const storeContext = useContext(StoreContext);
  const toastContext = useContext(ToastContext);
  const loaderContext = useContext(LoaderContext);
  const modalContext = useContext(ModalContext);
  const params = useParams() as any;

  const tenant = storeContext?.tenantsList?.find(
    (app: any) => app?.idolId === params?.idolId
  );

  useEffect(() => {
    storeContext?.setCurrentTenant(tenant);
  }, [tenant]);

  return (
    <View title="Information locataire">
      <div className="applications__section">
        <div className="idol-header__container">
          <div className="idol-header__data">
            <h1>{tenant?.name}</h1>
          </div>
          <div className="idol-header__actions">
            <Button
              onClick={() => history.push("/tenants/edit/" + params.tenantId)}
              color={Color.primary}
            >
              Modifier Le locataire
            </Button>
          </div>
        </div>
        <div>
          <h2>Récapitulatif identité</h2>
          <SummaryElement label="Nom" value={tenant?.name} />
          <SummaryElement
            label="Numero de téléphone"
            value={tenant?.phoneNumber}
          />
          <SummaryElement label="Mail" value={tenant?.email} />
          <SummaryElement
            label="Salaire mensuel connu"
            value={tenant?.knownMonthlySalary}
          />
          <SummaryElement
            label="Date de naissance"
            value={new Date(tenant?.dateOfBirth).toDateString()}
          />
        </div>
      </div>
    </View>
  );
}

export default Tenant;
