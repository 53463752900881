
import React, { FunctionComponent } from 'react';
import "./File.scss"

type FileProps = {
    onChange: (value: any) => void,
    icon?: any,
    type?: "file",
}

export const File: FunctionComponent<FileProps> = ({ onChange, icon, type = "file" }) => {
    return <div className="input-container">
        <label className="input-label row jc-center"><input
            multiple={true}
            className="input-file"
            type="file"
            onChange={(event) => onChange(event.target.files)}
        />Choisir</label>

    </div>
}
