import React, { useState } from "react";


let ModalContext = React.createContext({
    open: (modal: any) => { },
    close: () => { },
});


let ModalProvider = ({ children }: any) => {
    const [modal, setModal] = useState(null as any)

    const open = (modal: any) => {
        setModal(modal)
    }

    const close = () => {
        setModal(null)
    }

    return (
        <ModalContext.Provider value={{ open, close }}>
            {modal}
            <div className={`${modal ? 'blur-backdrop' : ''} `}>{children}</div>
        </ModalContext.Provider>
    );
};

export { ModalContext, ModalProvider };