import { useContext } from "react";
import { useAsync } from "react-async-hook";
import { ToastContext } from "../../contexts/ToastContext";
import { Color, Icons, Toast as ToastType } from "../../types";
import "./Toast.scss";


interface ToastProps extends ToastType {
    index: number,
}

export const Toast = ({ color, message, index, time, children }: ToastProps) => {
    const toastContext = useContext(ToastContext)

    const closeToast = useAsync(async () => {
        setTimeout(() => {
            toastContext.close(index)
        }, time || 5000)
    }, [time])

    const colorToIcon = () => {
        switch (color) {
            case Color.success:
                return Icons.Check
            case Color.danger:
                return Icons.CrossCircle
            case Color.warning:
                return Icons.Exclamation
            default:
                return Icons.Info
        }
    }

    return <div
        className={`toast toast--${color.toLowerCase()}`}
        onClick={() => toastContext.close(index)}
    >
        <div className="col flex-1">
            {message && <p className="toast__message">{message}</p>}
            {children}
        </div>
        <div className="toast__icon">
            <i className={`fi fi-rr-${Icons.CrossSmall}`} onClick={() => toastContext.close(index)} />
        </div>
    </div>
}
