
import "./Directory.scss";

import fileDocument from "../../../assets/fileDocument.png";
import { useHistory } from "react-router";
import { useContext } from "react";
import { StoreContext } from "../../../contexts/StoreContext";
import { LoaderContext } from "../../../contexts/LoaderContext";
import { Loader } from "../../Loader/Loader";

interface DirectoryProps {
    key: any,
    path: string,
    name?: string,
    modal?: boolean
}

export const Directory = ({ key, path, name, modal }: DirectoryProps) => {
    const storeContext = useContext(StoreContext)
    const loaderContext = useContext(LoaderContext)
    const history = useHistory()
    return <div key={key} className="margin-right" onClick={async () => {
        if (!modal) {
            loaderContext.open(<Loader />)
            storeContext.galleryHook.execute(history.location.pathname + "/" + name)
            loaderContext.close()
            history.push(history.location.pathname + "/" + name)
        }
    }}>
        <img className="file" src={fileDocument} />
        <p>{name}</p>
    </div>
}


