import React, { useState } from "react";


let LoaderContext = React.createContext({
    open: (modal: any) => { },
    close: () => { },
});


let LodaerProvider = ({ children }: any) => {
    const [loader, setLoader] = useState(null as any)

    const open = (loader: any) => {
        setLoader(loader)
    }

    const close = () => {
        setLoader(null)
    }

    return (
        <LoaderContext.Provider value={{ open, close }}>
            {loader}
            <div className={`${loader ? 'blur-backdrop' : ''} `}>{children}</div>
        </LoaderContext.Provider>
    );
};

export { LoaderContext, LodaerProvider };