import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../contexts/StoreContext";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../contexts/ToastContext";
import { Avatar, Button, Field, Input, Tag, View } from "../../components";
import { Color } from "../../types";
import { Loader } from "../../components/Loader/Loader";
import { LoaderContext } from "../../contexts/LoaderContext";
import firebase from "firebase";
import { Document } from "../../components/Document/Document";
import "./editHouse.scss";

import moment from "moment";
import { ModalContext } from "../../contexts/ModalContext";
import { useForm } from "../../hooks/useForm";
import { ModalGallery } from "../../modals/ModalGallery/ModalGallery";
import DatePicker from "react-date-picker";

const DATE_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
const MILISECOND_REGEX = /^[0-9]{13}$/;

function EditHouse() {
  const history = useHistory();
  const storeContext = useContext(StoreContext);
  const toastContext = useContext(ToastContext);
  const loaderContext = useContext(LoaderContext);
  const modalContext = useContext(ModalContext);
  const params = useParams() as any;

  const house = storeContext?.housesList?.find(
    (idl: any) => idl.idolId === params?.idolId
  );

  const form = useForm({
    onSubmit: async (e: any) => {
      try {
        loaderContext.open(<Loader />);
        await firebase
          .firestore()
          .collection("houses")
          .doc(params.houseId)
          .update({
            displayName: e?.displayName || "",
            rent: e?.rent,
            address: e?.address || "",
            livableSize: e?.livableSize || "",
            fieldSize: e?.fieldSize || "",
          });
        loaderContext.close();
        toastContext.open({
          color: Color.success,
          message: "Le logement a été modifié avec succès.",
        });
        history.push("/houses");
      } catch (error: any) {
        loaderContext.close();
        toastContext.open({ color: Color.danger, message: error.message });
      }
    },
    initialData: { ...house },
    rules: {
      displayName: [{ required: true, message: "Ce champ est requis" }],
      rent: [{ required: true, message: "Ce champ est requis" }],
      address: [{ required: true, message: "Ce champ est requis" }],
    },
  });

  return (
    <View>
      <div className="applications__section">
        <div>
          <h1>Modification du logement {house.displayName}</h1>
          <form className="signin__form">
            <Field required label="Nom" status={form.status?.displayName}>
              <Input.Text
                placeholder="Entrez un nom pour le logement..."
                onChange={(value: any) => form.onChange("displayName", value)}
                value={form.data.displayName}
              />
            </Field>
            <Field required label="Adresse" status={form.status?.address}>
              <Input.Text
                placeholder="Entrez l'adresse"
                onChange={(value: any) => form.onChange("address", value)}
                value={form.data.address}
              />
            </Field>
            <Field required label="Loyer (€)" status={form.status?.rent}>
              <Input.Text
                placeholder="Entrez le loyer"
                onChange={(value: any) => form.onChange("rent", value)}
                value={form.data.rent}
              />
            </Field>
            <Field
              label="Surface habitable (m2)"
              status={form.status?.livableSize}
            >
              <Input.Text
                placeholder="Entrez la surface habitable en m2"
                onChange={(value: any) => form.onChange("livableSize", value)}
                value={form.data.livableSize}
              />
            </Field>

            <Field label="Surface Terrain (m2)" status={form.status?.fieldSize}>
              <Input.Text
                placeholder="Entrez la taille du terrain si il y en a un en m2"
                onChange={(value: any) => form.onChange("fieldSize", value)}
                value={form.data.fieldSize}
              />
            </Field>

            <Field label="Image(s)" status={form.status?.images}>
              <Button
                color={Color.primary}
                onClick={() => {
                  modalContext?.open(
                    <ModalGallery
                      updateCallback={(file: any) => {
                        if (!!file.directory) {
                          storeContext.galleryHook.execute(file.directory);
                        }
                      }}
                      select={(file: any) => {
                        modalContext?.close();
                        form.data.images = file?.picture || file?.thumbnail;
                      }}
                    />
                  );
                }}
              >
                Choisir
              </Button>
              {form?.data?.avatar && (
                <Document.Image
                  url={form?.data?.avatar}
                  thumbnail={form?.data?.avatar}
                  key={"img_" + form?.data?.avatar}
                />
              )}
            </Field>
          </form>
        </div>

        <div>
          <Button color={Color.primary} onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button
            className="margin-left"
            color={Color.warning}
            onClick={form.submit}
          >
            Confirmer
          </Button>
        </div>
      </div>
    </View>
  );
}

export default EditHouse;
