
import "./SummaryElement.scss";


interface SummaryElementProps {
    value: any,
    label: any
}

export const SummaryElement = ({
    label,
    value
}: SummaryElementProps) => {
    const renderArray = (value: any) => {
        let str = ""
        value.forEach((val: string, index: number) => {
            if (index)
                str += ", "
            str += val
        })
        return str
    }
    return <div className="summary-element">
        <div className="summary-element__label">{label}</div>
        <div className="summary-element__value">{Array.isArray(value) ? renderArray(value) : value}</div>
    </div>
}


