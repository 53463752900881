
import React, { FunctionComponent } from 'react';
import { Color } from '../../../types';
import "./Button.scss"

type ButtonProps = {
    children: any,
    onClick: () => void,
    color?: Color,
    className?: string,
    loading?: boolean
}

export const Button: FunctionComponent<ButtonProps> = ({
    children,
    onClick,
    color,
    className,
    loading
}) => {
    return <button onClick={onClick} type="button" className={`button button--${color || Color.default} ${className}`}>
        {loading ? <span className="button__loading"><i className="fi-rr-spinner-alt spin"></i></span> : children}
    </button>
}