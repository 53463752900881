
import "./Gallery.scss";


interface GalleryProps {
    key: any,
    url: any,
    onClick?: any
    selected?: boolean
}

export const GalleryImg = ({ key, url, onClick, selected }: GalleryProps) => {
    return <div key={key} className="margin-right" onClick={() => onClick()}>
        <img className={selected ? "selected gallery" : "gallery"}
            src={url || "https://lesexpertsdurecouvrement.com/wp-content/uploads/2015/11/default-avatar.jpg"} />
    </div>
}


