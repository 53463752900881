import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase";

const errorMessages: any = {
  "auth/account-exists-with-different-credential": "Email already used",
  "auth/credential-already-in-use": "User already exist",
  "auth/email-already-in-use": "Email already used",
  "auth/user-disabled": "User disabled",
  "auth/network-request-failed": "Bad connection",
  "auth/app-deleted": "App Deleted",
  "auth/app-not-authorized": "App not authorized",
  "auth/argument-error": "Argument error",
  "auth/timeout": "timeout",
};

type ContextProps = {
  user: firebase.User | null | any;
  authenticated: boolean;
  setUser: any;
  loadingAuthState: boolean;
  signInWithEmailAndPassword: (email: string, password: string) => void;
  signOut: () => void;
};

export const AuthContext = React.createContext({
  user: null,
  authenticated: false,
  setUser: () => {},
  loadingAuthState: false,
  signInWithEmailAndPassword: async () => {},
  signOut: async () => {},
} as ContextProps);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(null as firebase.User | null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  const history = useHistory();
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user: any) => {
      setUser(user);
      if (user?.uid) {
        const adminRef = (await firebase
          .firestore()
          .collection("users")
          .doc(user?.uid)
          .get()) as any;
        if (!adminRef.exists) {
          signOut();
          setAuthenticated(false);
        } else {
          setUser({ ...adminRef.data(), userId: user?.uid });
          setAuthenticated(true);
        }
      }
      setAuthenticated(!!user);
      setLoadingAuthState(false);
    });
  }, []);

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (err: any) {
      throw errorMessages[err.code] || err;
    }
  };

  const signOut = async () => {
    try {
      await firebase.auth().signOut();
    } catch (err: any) {
      throw errorMessages[err.code] || err;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loadingAuthState,
        authenticated: user !== null,
        setUser,
        signInWithEmailAndPassword,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
