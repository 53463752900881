
import { useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';

enum StatusTypes {
    Error = "error",
    Success = "success",
    Warning = "warning"
}

enum ValidateOn {
    Watch = "WATCH",
    Submit = "SUBMIT",
}


const verifyRules = (rules?: Array<Rule>, value?: any, generatedFields?: any) => {
    if (rules) {
        for (const rule of rules) {
            for (const [ruleKey, ruleValue] of Object.entries(rule)) {
                if (ruleKey !== "message" && !verify[ruleKey](value, ruleValue, generatedFields))
                    return { type: StatusTypes.Error, message: rule.message }
            }
        }
    }
    return { type: StatusTypes.Success }
}

const verify: any = {
    required: (value: any) => {
        if (value && typeof value === "object" && Object.entries(value))
            return true
        else if (value && typeof value === "number" && (value || value === 0))
            return true
        else if (value)
            return true
        return false
    },
    regex: (value: any, param: any) => {
        return !!new RegExp(param).test(value)
    },
    func: (value: any, param: any, generatedFields: any) => {
        if (typeof param === "function")
            return !!param(generatedFields)
        return false
    }
}

export interface Rule {
    required?: boolean,
    regex?: any,
    message: string,
    func?: Function
}

export interface Rules {
    [key: string]: Array<Rule>
}

type useFormProps = {
    onSubmit: (data: any) => void,
    initialData?: any,
    initialStatus?: any,
    rules?: Rules
}

export const useForm = ({ onSubmit, initialData = {}, initialStatus = {}, rules }: useFormProps) => {

    const [data, setData] = useState(initialData as any)
    const [status, setStatus] = useState(initialStatus as any)
    const [validateOn, setValidateOn] = useState(ValidateOn.Submit)
    const onAsyncSubmit = useAsyncCallback(async () => {
        setValidateOn(ValidateOn.Watch)
        validate() && await onSubmit(data)
    })

    useEffect(() => {
        if (validateOn === ValidateOn.Watch) {
            validate()
        }
    }, [data])

    const onChange = (key: string, value: any) => {
        const newFormData = Object.assign({}, { ...data }, { [key]: value })
        setData(newFormData)
    }


    const validate = () => {
        let newStatus: any = { ...status } as any
        if (typeof rules === "object") {
            for (const [key, value] of Object.entries(rules)) {
                newStatus[key] = verifyRules(value, data?.[key])
            }
        }
        setStatus(newStatus)
        if (Object.values(newStatus)?.findIndex((status: any) => status.type === StatusTypes.Error) >= 0)
            return false
        return true
    }

    return {
        data,
        status,
        onChange,
        validate,
        submit: onAsyncSubmit.execute,
        loading: onAsyncSubmit.loading
    };
}