import { FunctionComponent } from "react";
import logo from "../../assets/logo.png";
import { useMediaQuery } from "react-responsive";

import "./ViewWithForm.scss";

type ViewWithFormProps = {
  childrenForm?: any;
  className?: string;
  header?: any;
};

export const ViewWithForm: FunctionComponent<ViewWithFormProps> = ({
  childrenForm,
  header,
  className,
}) => {
  const isLaptop = useMediaQuery({
    query: "(min-width: 998px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 798px)",
  });

  return (
    <div
      className={`view-with-form ${className} view-with-form--${
        isLaptop ? "laptop" : "mobile"
      }`}
    >
      {isMobile && (
        <div className="mobile-warning">
          Pour une meilleur expérience, veuillez utiliser un ordinateur
        </div>
      )}
      <div className="view-with-form__infos">
        <p className="view-with-form__infos__logo">
          <img src={logo} className="view-with-form__infos__logo__img" />
          <span className="view-with-form__infos__logo__text">
            LYMPE IMMO ADMIN
          </span>
        </p>
      </div>
      <div className="view-with-form__form">{childrenForm}</div>
    </div>
  );
};
