import "./ModalGallery.scss";

import fileDocument from "../../../assets/fileDocument.png";
import { useHistory } from "react-router";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../contexts/StoreContext";
import { LoaderContext } from "../../../contexts/LoaderContext";
import { Loader } from "../../Loader/Loader";

interface ModalGalleryProps {}

export const ModalGallery = ({}: ModalGalleryProps) => {
  const storeContext = useContext(StoreContext);
  const loaderContext = useContext(LoaderContext);
  const history = useHistory();

  const getdirectoryArray = () => {
    const path = storeContext?.gallery?.currentDirectory;
    if (path?.charAt(0) === "/") {
      return storeContext?.gallery?.currentDirectory?.substring(1)?.split("/");
    } else {
      return storeContext?.gallery?.currentDirectory?.split("/");
    }
  };

  const [directory, setDirectory] = useState(getdirectoryArray());

  const goTo = async (tab: any) => {
    loaderContext.open(<Loader />);
    const qs = tab?.length !== 1 ? "/" + tab.join("/") : "/" + tab[0];
    await storeContext?.galleryHook.execute(qs);
    loaderContext.close();
  };

  useEffect(() => {
    setDirectory(getdirectoryArray());
  }, [storeContext?.gallery]);

  return (
    <div className="row ai-center">
      {!!directory &&
        directory?.map((el: string, index: number) => {
          if (index < directory?.length - 1) {
            const newTab = directory.slice(0, index + 1);
            return (
              <h1 onClick={() => goTo(newTab)} className="margin-left pointer">
                {el + " >>"}
              </h1>
            );
          } else {
            return <h1 className="margin-left">{el}</h1>;
          }
        })}
    </div>
  );
};
