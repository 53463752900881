import {useEffect, useState} from "react";
import {useAsyncCallback} from "react-async-hook";


interface useDataProps {
    query: any,
    executeOnStart?: boolean
}

export const useData = ({query, executeOnStart = false}: useDataProps) => {

    const [data, setData] = useState(null as any)
    const getData = useAsyncCallback(query)

    useEffect(() => {
        if (executeOnStart) {
            getData.execute()
        }
    }, [])

    useEffect(() => {
        setData(getData.result)
    }, [getData.result])


    return {
        data,
        setData,
        ...getData
    }

}
