import { Directory } from "./Directory/Directory";
import { Sound } from "./Sound/Sound";
import { Pdf } from "./Pdf/Pdf";
import { Image, ImageSelector } from "./Image/Image";

export const Document = {
  Sound,
  Directory,
  Pdf,
  Image,
  ImageSelector,
};
interface AssetProps {
  file: any;
  selected?: boolean;
}

export const Asset = ({ file, selected }: AssetProps) => {
  if (file.type === "directory") {
    return (
      <Directory
        key={file.name + "_directory"}
        path={file.directory}
        name={file.name}
        modal={false}
      />
    );
  } else if (file.type === "file") {
    if (
      file.extension === "png" ||
      file.extension === "jpg" ||
      file.extension === "jpeg"
    ) {
      return (
        <Image
          key={file.name + "_fileName"}
          thumbnail={file?.thumbnail}
          name={file.name}
          url={file?.url}
        />
      );
    }
  }
  return <div></div>;
};

export const AssetSelector = ({ file, selected }: AssetProps) => {
  if (file.type === "directory") {
    return (
      <Directory
        key={file.name + "_directory"}
        path={file.directory}
        name={file.name}
        modal={true}
      />
    );
  } else if (file.type === "file") {
    if (
      file.extension === "png" ||
      file.extension === "jpg" ||
      file.extension === "jpeg"
    ) {
      return (
        <ImageSelector
          key={file.name + "_fileName"}
          selected={selected}
          thumbnail={file?.thumbnail}
          name={file.name}
          url={file?.url}
        />
      );
    }
  }
  return <div></div>;
};
