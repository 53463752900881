
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside"
import "./Dropdown.scss";


interface DropdownProps {
    visible?: boolean,
    children?: any,
    onClickOutside: () => void,
    minWidth?: number
}

export const Dropdown = ({
    visible,
    children,
    onClickOutside,
    minWidth = 250
}: DropdownProps) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => onClickOutside());

    return <div
        ref={ref}
        className={`dropdown animated dropdown--${visible ? 'open' : 'close'}`}
        onClick={(e) => { e.stopPropagation() }}
        style={{ minWidth: minWidth + "px" }}

    >
        <span className={`dropdown__indicator`}></span>
        <div className="dropdown__content">
            {children ? children : <p>Empty</p>}
        </div>
    </div>
}


