import React, { useContext, useEffect, useState } from "react";

import { useData } from "../hooks/useData";
import { Data } from "../api/data";
import { useOnSnapshotTenants } from "../hooks/useOnSnapshotTenants";
import { useOnSnapshotHouses } from "../hooks/useOnSnapshotHouses";
import { useOnSnapshotPaiments } from "../hooks/useOnSnapshotPaiments";
import firebase from "firebase";
import { AuthContext } from "./AuthContext";

const errorMessages: any = {
  "auth/account-exists-with-different-credential": "Email already used",
  "auth/credential-already-in-use": "User already exist",
  "auth/email-already-in-use": "Email already used",
  "auth/user-disabled": "User disabled",
  "auth/network-request-failed": "Bad connection",
  "auth/app-deleted": "App Deleted",
  "auth/app-not-authorized": "App not authorized",
  "auth/argument-error": "Argument error",
  "auth/timeout": "timeout",
};
type ContextProps = {
  tenantsList: any;
  housesList: any;
  paiementsList: any;
  gallery: any;
  currentTenant: any;
  currentHouse: any;
  setCurrentTenant: any;
  setCurrentHouse: any;
  galleryHook: any;
  createStripePortalSession: any;
};
export const StoreContext = React.createContext({} as ContextProps);

export const StoreProvider = ({ children }: any) => {
  const authContext = useContext(AuthContext);

  const [currentTenant, setCurrentTenant] = useState({} as any);
  const [currentHouse, setCurrentHouse] = useState({} as any);
  const [gallery, setGallery] = useState({} as any);

  const galleryHook = useData({
    query: (path: string) => Data.getGallery(path),
    executeOnStart: false,
  });

  const tenantsList = useOnSnapshotTenants();
  const housesList = useOnSnapshotHouses();
  const paiementsList = useOnSnapshotPaiments();

  const createStripePortalSession = () => {
    const createPortalUser = firebase
      .functions()
      .httpsCallable("createPortalUser");
    return createPortalUser({
      customerId: authContext?.user?.stripe_id,
      returnUrl: window.location.origin,
    });
  };

  useEffect(() => {
    setGallery(galleryHook.data);
  }, [galleryHook.data]);

  return (
    <StoreContext.Provider
      value={{
        tenantsList,
        housesList,
        paiementsList,
        gallery,
        currentTenant,
        currentHouse,
        setCurrentHouse,
        setCurrentTenant,
        galleryHook,
        createStripePortalSession,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
