import { useContext } from "react"
import { Button } from "../../components"
import { Modal } from "../../components/Modal/Modal"
import { ModalContext } from "../../contexts/ModalContext";
import { Color } from "../../types"
import "./ModalConfirmation.scss";

interface ModalConfirmationProps {
    onContinue?: (data?: any) => any,
    callback: any,
    title: string
}

const FORM_DATA_DEFAULT = {
    name: "",
}

export const ModalConfirmation = ({ onContinue, callback, title }: ModalConfirmationProps) => {
    const modalContext = useContext(ModalContext)

    const next = () => {
        modalContext.close();
        onContinue?.()
    }

    const close = () => {
        modalContext.close()
    }



    return <Modal>
        <div className="modal-confirmation__body">

            <h1>{title}</h1>

        </div>
        <div className="modal-confirmation__footer">
            <Button
                color={Color.primary}
                onClick={close}
            >
                Annuler
            </Button>
            <Button
                className="margin-left"
                color={Color.warning}
                onClick={() => callback()}
            >
                Confirmer
            </Button>
        </div>
    </Modal>
}
