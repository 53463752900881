import { useContext, useEffect, useState } from "react"
import { Button, Field, Input } from "../../components"
import { Modal } from "../../components/Modal/Modal"
import { ModalContext } from "../../contexts/ModalContext";
import { useForm } from "../../hooks/useForm";
import { Color } from "../../types"
import "./ModalAddToGallery.scss";
import { ToastContext } from "../../contexts/ToastContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { Loader } from "../../components/Loader/Loader";
import axios from 'axios';
import { GalleryImg } from "../../components/Gallery/Gallery";
import { useHistory } from "react-router-dom";

interface ModalAddToGalleryProps {
    onContinue?: (data?: any) => any,
    path?: string,
    callback?: any
}


export const ModalAddToGallery = ({ onContinue, path, callback }: ModalAddToGalleryProps) => {
    const modalContext = useContext(ModalContext)
    const toastContext = useContext(ToastContext)
    const loaderContext = useContext(LoaderContext)
    const [assets, setAssets] = useState([] as any)

    const next = () => {
        modalContext.close();
        onContinue?.()
    }

    const close = () => {
        modalContext.close()
    }
    const URLserver = "https://assets.s2mtechnologies.com/upload?directory=" + path
    const URLthumbnailServer = "https://assets.s2mtechnologies.com/upload?thumbnail=true&directory=" + path

    const form = useForm({
        onSubmit: async (e: any) => {
            try {
                loaderContext.open(<Loader />)
                for (let i = 0; i < assets.length; i++) {
                    const newFile = assets[i]
                    const formData = new FormData();
                    formData.append(
                        "file",
                        newFile,
                        newFile.name
                    );
                    await axios.post(URLthumbnailServer, formData)
                }
                callback()
                close()
                loaderContext.close()
                toastContext.open({
                    color: Color.success,
                    message: "Les assets ont été ajouté"
                })
            } catch (error: any) {
                loaderContext.close()
                toastContext.open({ color: Color.danger, message: error.message })
            }
        },
    })
    return <Modal>
        <div className="modal-confirmation__body">

            <h1>Ajouts à la bibliothèque d'image</h1>
            <form className="signin__form">
                <Field
                    required
                    label="Choisissez vos fichier"
                    status={form.status?.file}
                >
                    <Input.File
                        onChange={(value: any) => setAssets([...value])}
                    />

                </Field>
            </form>

            {!!assets && <div className="grid-wrapper">
                {assets?.length > 0 && assets?.map((asset: any, index: number) => {
                    return <GalleryImg
                        key={asset?.name}
                        url={URL.createObjectURL(asset)}
                    />
                }
                )}
            </div>
            }
        </div>
        <div className="modal-confirmation__footer">
            <Button
                color={Color.primary}
                onClick={close}
            >
                Annuler
            </Button>
            <Button
                className="margin-left"
                color={Color.warning}
                onClick={form.submit}
            >
                Confirmer
            </Button>
        </div>
    </Modal>
}
