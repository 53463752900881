import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'

const NotGuardAuthenticated = ({ children, ...rest }: any) => {
    const authContext = useContext(AuthContext)
    // Add your own authentication on the below line.

    return (
        <Route
            {...rest}
        >
            {!authContext?.authenticated
                ? children
                : <Redirect to={{ pathname: '/' }} />}
        </Route >
    )
}

export default NotGuardAuthenticated