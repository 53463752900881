import { useContext } from "react";
import ReactDOM from "react-dom";
import { LoaderContext } from "../../contexts/LoaderContext";
import "./Loader.scss";

export const Loader = () => {
    const loaderContext = useContext(LoaderContext)

    return ReactDOM.createPortal(
        <div className="loader">
            <div className="loader__backdrop"></div>
            <div className="loader__container">
                <div className="loader__container__wrapper">
                    <div className="loader__content">
                        <div className="loader__content__header">
                            <h3 className="loader__content__header__title">En cours de chargement ...</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.querySelector("#loader-root") as HTMLInputElement
    );
}