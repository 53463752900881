import firebase from "firebase";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

export const useOnSnapshotHouses = () => {
  const authContext = useContext(AuthContext);
  const [error, setError] = useState(null as any);
  const [loading, setLoading] = useState(true);
  const [houses, setHouses] = useState([] as any);

  useEffect(() => {
    if (authContext?.user?.uid) {
      firebase
        .firestore()
        .collection("houses")
        .where("userId", "==", authContext?.user?.uid)
        .onSnapshot(
          (snapshot) => {
            const newHouses = [] as any;
            snapshot.forEach((doc) => {
              newHouses.push({ houseId: doc.id, ...doc.data() });
            });
            setLoading(false);
            setHouses(newHouses);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }, [authContext?.user]);
  return houses;
};
