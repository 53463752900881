import { useContext, useEffect, useState } from "react"
import { Button, Field, Input } from "../../components"
import { Modal } from "../../components/Modal/Modal"
import { ModalContext } from "../../contexts/ModalContext";
import { useForm } from "../../hooks/useForm";
import { Color } from "../../types"
import "./ModalGallery.scss";
import { ToastContext } from "../../contexts/ToastContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { Loader } from "../../components/Loader/Loader";
import axios from 'axios';
import { GalleryImg } from "../../components/Gallery/Gallery";
import { AssetSelector } from "../../components/Document/Document";
import { StoreContext } from "../../contexts/StoreContext";
import { BreadCrumb } from "../../components/BreadCrumb/BreadCrumb";

interface ModalGalleryProps {
    onContinue?: (data?: any) => any,
    select: any,
    updateCallback?: any,
}

const URLserver = "https://assets.s2mtechnologies.com"

export const ModalGallery = ({ onContinue, select }: ModalGalleryProps) => {
    const modalContext = useContext(ModalContext)
    const storeContext = useContext(StoreContext)
    const [asset, setAsset] = useState({} as any)
    const [nameFilter, setNameFilter] = useState("" as string)

    const next = () => {
        modalContext.close();
        onContinue?.()
    }

    const close = () => {
        modalContext.close()
    }

    const onAssetClick = (file: any) => {
        if (!!file.directory) {
            storeContext.galleryHook.execute(file.directory)
        } else {
            if (JSON.stringify(file) === JSON.stringify(asset)) {
                setAsset({})
            } else {
                setAsset(file)
            }
        }
    }

    const onValidate = () => {
        close()
        select(asset)
    }
    return <Modal>
        <div className="modal-gallery__container">
            <div className="application__header-container">
                <div className="row ai-center jc-flex-end">
                    <BreadCrumb.ModalGallery />
                </div>
                <div className="row ai-center jc-flex-end margin-right">
                    <Field
                        required
                        label="Rechercher"
                        status={true}
                    >
                        <Input.Text
                            placeholder="rechercher..."
                            onChange={(value: any) => setNameFilter(value)}
                            value={nameFilter}
                        />
                    </Field>
                </div>
            </div>


            <div className="grid-wrapper">
                {storeContext?.gallery?.assets?.length > 0 && storeContext.gallery?.assets?.filter((file: any) => {
                    if (!!nameFilter) {
                        return file?.name?.toLowerCase()?.includes(nameFilter.toLowerCase()) || file?.type === "directory"
                    } else {
                        return true
                    }
                })?.map((el: any, index: number) => {
                    return <div onClick={() => onAssetClick(el)}>
                        <AssetSelector selected={JSON.stringify(el) === JSON.stringify(asset)} file={el} />
                    </div>
                }
                )}
            </div>
            {!!asset && <Button
                className="margin-left"
                color={Color.warning}
                onClick={() => onValidate()}
            >
                Valider
            </Button>}
        </div>

    </Modal>
}
