import axios from "axios";

const URL = "https://assets.s2mtechnologies.com";

const Data = {
  getGallery: async (path: string) => {
    let newGallery = [] as any;
    const getUrl = URL + "/files?directory=" + path;
    const files: any = await axios.get(getUrl);
    for (let file of files.data) {
      newGallery.push(file);
    }
    return { assets: newGallery, currentDirectory: path };
  },
};

export { Data };
