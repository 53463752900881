import { useContext, useEffect, useState } from "react";
import { View } from "../../components";
import { StoreContext } from "../../contexts/StoreContext";

function Home() {
  const storeContext = useContext(StoreContext);
  const [firstGalleryLoaded, setFirstGalleryLoaded] = useState(false);
  useEffect(() => {
    if (!firstGalleryLoaded) {
      storeContext?.galleryHook.execute("gallery");
      setFirstGalleryLoaded(true);
    }
  }, []);
  return (
    <View>
      <div>
        <h2>Bienvenue sur la page administrateur</h2>
        <p>Cette plateforme facilitera la gestion de vos biens immobiliers</p>
      </div>
    </View>
  );
}

export default Home;
