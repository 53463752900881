import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../contexts/StoreContext";
import { Button, Tag, View, Avatar } from "../../components";
import "./finances.scss";
import { useHistory } from "react-router-dom";
import { Color } from "../../types";
import { ModalContext } from "../../contexts/ModalContext";
import firebase from "firebase";
import { LoaderContext } from "../../contexts/LoaderContext";
import { ToastContext } from "../../contexts/ToastContext";
import { Loader } from "../../components/Loader/Loader";
import { ModalConfirmation } from "../../modals/ModalConfirmation/ModalConfirmation";
import moment from "moment";
import { ModalPictures } from "../../modals/ModalPictures/ModalPictures";

function Finances() {
  const storeContext = useContext(StoreContext);
  const modalContext = useContext(ModalContext);
  const toastContext = useContext(ToastContext);
  const loaderContext = useContext(LoaderContext);
  const history = useHistory();

  console.log("Finacnes: ", storeContext?.paiementsList);

  const validatePaiment = (paimentId: string) => {
    firebase
      .firestore()
      .collection("paiments")
      .doc(paimentId)
      .update({ state: "RECEIVED" })
      .then(() => {
        toastContext.open({
          color: Color.success,
          message: "Validation avec succès",
        });
      })
      .catch((err: any) => {
        toastContext.open({
          color: Color.danger,
          message: "Erreur lors de la validation.",
        });
      });
  };
  return (
    <View title="Mes Finances">
      <div className="doctors__section">
        <div className="row ai-center jc-flex-end margin-bottom">
          <Button
            onClick={() => history.push("/tenants/create")}
            color={Color.primary}
          >
            Ajouter un locataire
          </Button>
        </div>

        <div className="col">
          {storeContext?.paiementsList
            ?.sort((a: any, b: any) => b?.creationDate - a?.creationDate)
            ?.map((el: any, index: number) => {
              const tenant = storeContext?.tenantsList?.find(
                (t: any) => t.tenantId === el?.tenantId
              );
              const house = storeContext?.housesList?.find(
                (h: any) => h.houseId === el?.houseId
              );
              console.log("tenant :", tenant);
              console.log("house :", house);
              return (
                <div
                  key={el?.tenantId + index}
                  className="table-body-row row ai-center flex-1"
                >
                  <div className="col ai-flex-start flex-1">
                    <div className="col ai-flex-start flex-1">
                      <div className="flex row ai-center">
                        <p className="centers__center-row__label">
                          {house?.displayName}
                        </p>
                        {el?.rent && (
                          <p className="margin-left">{el?.rent + "€"}</p>
                        )}
                      </div>
                      <p className="margin-left">{tenant?.name}</p>
                      <span className="centers__center-row__label__cassette-id margin-top pointer ">
                        {new Date(el?.creationDate).toDateString()}
                      </span>
                    </div>
                  </div>
                  <div className="flex row ai-center">
                    {el.state === "TO_VALIDATE" && (
                      <Tag
                        className="margin-right margin-left pointer"
                        color={Color.danger}
                        label="Déclarer recu"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          validatePaiment(el.paimentId);
                        }}
                      />
                    )}
                    {el.state === "RECEIVED" && (
                      <Tag
                        className="margin-right margin-left"
                        color={Color.success}
                        label="Recu"
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </View>
  );
}

export default Finances;
