import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../contexts/StoreContext";
import { Button, Field, Input, View } from "../../components";
import { Color } from "../../types";
import "./gallery.scss";
import { ModalContext } from "../../contexts/ModalContext";
import { ModalAddToGallery } from "../../modals/ModalAddToGallery/ModalAddToGallery";
import { GalleryImg } from "../../components/Gallery/Gallery";
import { ModalPictures } from "../../modals/ModalPictures/ModalPictures";
import { Asset, Document } from "../../components/Document/Document";
import { useHistory } from "react-router";
import { LoaderContext } from "../../contexts/LoaderContext";
import { ToastContext } from "../../contexts/ToastContext";
import { Loader } from "../../components/Loader/Loader";
import axios from "axios";
import { BreadCrumb } from "../../components/BreadCrumb/BreadCrumb";

function Gallery() {
  const modalContext = useContext(ModalContext);
  const storeContext = useContext(StoreContext);
  const loaderContext = useContext(LoaderContext);
  const toastContext = useContext(ToastContext);
  const history = useHistory();
  const [newDirectory, setNewDirectory] = useState(false);
  const [directoryName, setDirectoryName] = useState("");
  const [filterName, setFilterName] = useState("");

  useEffect(() => {
    storeContext?.galleryHook.execute(history.location.pathname.substring(1));
  }, []);

  const updateAssetsList = async () => {
    loaderContext.open(<Loader />);
    await storeContext?.galleryHook.execute(
      history.location.pathname.substring(1)
    );
    loaderContext.close();
  };

  const pushNewDirectory = async () => {
    const newDirectoryPath =
      history.location.pathname.substring(1) + "/" + directoryName;
    const URLserver =
      "https://assets.s2mtechnologies.com/directory?directory=" +
      newDirectoryPath;
    try {
      loaderContext.open(<Loader />);
      await axios.post(URLserver);
      await storeContext?.galleryHook.execute(newDirectoryPath);
      history.push(history.location.pathname + "/" + directoryName);

      loaderContext.close();
      toastContext.open({
        color: Color.success,
        message: "Les assets ont été ajouté",
      });
      setNewDirectory(!newDirectory);
    } catch (error: any) {
      loaderContext.close();
      setNewDirectory(!newDirectory);
      toastContext.open({ color: Color.danger, message: error.message });
    }
  };

  const sortAssets = (assets: any) => {
    const directoryAssets = assets.filter(
      (asset: any) => asset.type === "directory"
    );
    const fileAssets = assets
      .filter((asset: any) => asset.type === "file")
      .sort();
    return directoryAssets.concat(fileAssets);
  };

  return (
    <View title="Bibliothèque d'images">
      <BreadCrumb.Gallery />

      <div className="application__header-container">
        <div className="row ai-center jc-flex-end">
          <div className="margin-right">
            <Button
              onClick={() =>
                modalContext.open(
                  <ModalAddToGallery
                    callback={() => updateAssetsList()}
                    path={history.location.pathname.substring(1)}
                  />
                )
              }
              color={Color.primary}
            >
              Ajouter un asset
            </Button>
          </div>
          <Button
            onClick={() => setNewDirectory(!newDirectory)}
            color={Color.primary}
          >
            Nouveau Dossier
          </Button>
        </div>
        <div className="row ai-center jc-flex-end">
          <Field required label="Recherche" status={true}>
            <Input.Text
              placeholder="Rechercher..."
              onChange={(value: any) => setFilterName(value)}
              value={filterName}
            />
          </Field>
        </div>
      </div>

      {newDirectory && (
        <div className="row ai-center">
          <Field required label="Nom du fichier" status={true}>
            <Input.Text
              placeholder="Entrez le nom"
              onChange={(value: any) => setDirectoryName(value)}
              value={directoryName}
            />
          </Field>
          <Button
            className="margin-left"
            onClick={() => pushNewDirectory()}
            color={Color.primary}
          >
            Valider la création
          </Button>
          <Button
            className="margin-left"
            onClick={() => setNewDirectory(!newDirectory)}
            color={Color.primary}
          >
            Annuler
          </Button>
        </div>
      )}

      <div className="gallery__grid-wrapper x-margin-top">
        {storeContext?.gallery?.assets?.length > 0 &&
          sortAssets(storeContext?.gallery?.assets)
            ?.filter((asset: any) => {
              if (!!filterName) {
                return (
                  (asset?.name
                    ?.toLowerCase()
                    ?.includes(filterName.toLowerCase()) &&
                    !!asset.thumbnail) ||
                  asset?.type === "directory"
                );
              } else {
                return true;
              }
            })
            ?.map((el: any, index: number) => {
              return <Asset key={el?.name} file={el} />;
            })}
      </div>
    </View>
  );
}

export default Gallery;
