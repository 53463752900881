import React, { FunctionComponent, useContext, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Button } from "../Form/Button/Button";
import logo from "../../assets/logo.png";
import "./View.scss";
import "./../../Color.scss";
import { Input } from "..";
import { AuthContext } from "../../contexts/AuthContext";
import { useMediaQuery } from "react-responsive";

type ViewProps = {
  children?: any;
  bodyClassName?: string;
  header?: any;
  overlayMessage?: string;
  title?: string;
};

export const View: FunctionComponent<ViewProps> = ({
  children,
  header,
  bodyClassName,
  overlayMessage,
  title,
}) => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState("");
  const isLaptop = useMediaQuery({
    query: "(min-width: 998px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 798px)",
  });

  return (
    <div className={`view ${isLaptop ? "view--laptop" : "view--mobile"}`}>
      {isMobile && (
        <div className="mobile-warning">
          Pour une meilleur expérience, veuillez utiliser un ordinateur
        </div>
      )}
      <div className="view__sidebar view__sidebar">
        <div className="view__sidebar__logo">
          {!isLaptop && (
            <div className="view__menu-button" onClick={() => setOpen(!open)}>
              <i className="fi-rr-menu-burger"></i>
            </div>
          )}
          <span onClick={() => history.push("/")} className="row ai-center">
            <img className="view__sidebar__logo__img" src={logo} />
            <span className="view__sidebar__logo__text">LYMPE Immo</span>
          </span>
        </div>
        <div className={`view__sidebar__links ${open ? "open" : "close"}`}>
          <NavLink
            exact
            to="/"
            className="view__sidebar__link"
            activeClassName="active"
          >
            <i className="fi fi-rr-home view__sidebar__link__icon"></i>
            <p className="view__sidebar__link__label">Acceuil</p>
          </NavLink>
          <NavLink
            to="/houses"
            className="view__sidebar__link"
            activeClassName="active"
          >
            <i className="fi fi-rr-building view__sidebar__link__icon"></i>
            <p className="view__sidebar__link__label">Logements</p>
          </NavLink>

          <NavLink
            to="/tenants"
            className="view__sidebar__link"
            activeClassName="active"
          >
            <i className="fi fi-rr-users view__sidebar__link__icon"></i>
            <p className="view__sidebar__link__label">Locataires</p>
          </NavLink>

          <NavLink
            to="/finances"
            className="view__sidebar__link"
            activeClassName="active"
          >
            <i className="fi fi-rr-bank view__sidebar__link__icon"></i>
            <p className="view__sidebar__link__label">Finances</p>
          </NavLink>

          <NavLink
            to="/gallery"
            className="view__sidebar__link"
            activeClassName="active"
          >
            <i className="fi fi-rr-gallery view__sidebar__link__icon"></i>
            <p className="view__sidebar__link__label">Galerie</p>
          </NavLink>

          <NavLink
            to="/settings"
            className="view__sidebar__link"
            activeClassName="active"
          >
            <i className="fi fi-rr-settings view__sidebar__link__icon"></i>
            <p className="view__sidebar__link__label">Réglages</p>
          </NavLink>
          <div
            className="view__sidebar__link"
            onClick={() => authContext?.signOut()}
          >
            <i className="fi-rr-sign-out view__sidebar__link__icon"></i>
            <p className="view__sidebar__link__label">Deconnexion</p>
          </div>
        </div>
      </div>
      <div className="view__main">
        {overlayMessage && (
          <div className="view__main__overlay">
            <p className="view__main__overlay__message">{overlayMessage}</p>
          </div>
        )}
        <div className="view__main__header row ai-center jc-space-between">
          <Button
            className="view__main__header__back-button"
            onClick={() => history.goBack()}
          >
            <i className="fi-rr-arrow-small-left"></i>
          </Button>
          <h1 className="view__main__header__title">{title}</h1>
          <div className="row ai-center">
            <Input.Text
              icon={<i className="fi-rr-search"></i>}
              value={search}
              onChange={setSearch}
              placeholder="Rechercher"
            />
            <Button className="margin-left" onClick={() => {}}>
              <i className="fi-rr-bell"></i>
            </Button>
            <div className="view__main__header__avatar margin-left" />
          </div>
        </div>
        <div className={`view__main__body ${bodyClassName}`}>{children}</div>
      </div>
    </div>
  );
};
