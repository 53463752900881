import firebase from "firebase";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

export const useOnSnapshotPaiments = () => {
  const authContext = useContext(AuthContext);
  const [error, setError] = useState(null as any);
  const [loading, setLoading] = useState(true);
  const [paiments, setPaiements] = useState([] as any);

  useEffect(() => {
    console.log("ID: ", authContext?.user?.uid);
    if (authContext?.user?.uid) {
      firebase
        .firestore()
        .collection("paiments")
        //.where("userId", "==", authContext?.user?.uid)
        .onSnapshot(
          (snapshot) => {
            const newPaiments = [] as any;
            snapshot.forEach((doc) => {
              newPaiments.push({ paimentId: doc.id, ...doc.data() });
            });
            setLoading(false);
            setPaiements(newPaiments);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }, [authContext?.user]);
  return paiments;
};
