import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./views/home/home";
import SignIn from "./views/SignIn/signin";
import GuardNotAuthenticated from "./guards/GuardNotAuthenticated/GuardNotAuthenticated";
import GuardAuthenticated from "./guards/GuardAuthenticated/GuardAuthenticated";
import Gallery from "./views/gallery/gallery";
import EditHouse from "./views/editHouse/editHouse";
import CreateHouse from "./views/createHouse/createHouse";
import House from "./views/house/house";
import Houses from "./views/houses/houses";
import CreateTenant from "./views/createTenant/createTenant";
import EditTenant from "./views/editTenant/editTenant";
import Tenant from "./views/tenant/tenant";
import Tenants from "./views/tenants/tenants";
import Finances from "./views/finances/finances";
import Settings from "./views/settings/settings";
function AppRouter() {
  return (
    <Router>
      <Switch>
        <GuardNotAuthenticated path="/signin">
          <SignIn />
        </GuardNotAuthenticated>
        <GuardAuthenticated path="/gallery">
          <Gallery />
        </GuardAuthenticated>

        <GuardAuthenticated path="/tenants/create">
          <CreateTenant />
        </GuardAuthenticated>
        <GuardAuthenticated path="/tenants/edit/:tenantId">
          <EditTenant />
        </GuardAuthenticated>
        <GuardAuthenticated path="/tenants/tenant/:tenantId">
          <Tenant />
        </GuardAuthenticated>
        <GuardAuthenticated path="/tenants">
          <Tenants />
        </GuardAuthenticated>

        <GuardAuthenticated path="/houses/create">
          <CreateHouse />
        </GuardAuthenticated>
        <GuardAuthenticated path="/houses/edit/:houseId">
          <EditHouse />
        </GuardAuthenticated>
        <GuardAuthenticated path="/houses/house/:houseId">
          <House />
        </GuardAuthenticated>
        <GuardAuthenticated path="/houses">
          <Houses />
        </GuardAuthenticated>
        <GuardAuthenticated path="/finances">
          <Finances />
        </GuardAuthenticated>
        <GuardAuthenticated path="/settings">
          <Settings />
        </GuardAuthenticated>
        <GuardAuthenticated path="/">
          <Home />
        </GuardAuthenticated>
      </Switch>
    </Router>
  );
}

export default AppRouter;
