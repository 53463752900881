import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'

const GuardAuthenticated = ({ children, ...rest }: any) => {
    const authContext = useContext(AuthContext)
    // Add your own authentication on the below line.

    return (
        <Route
            {...rest}
        >
            {authContext?.authenticated
                ? children
                : <Redirect to={{ pathname: '/signin' }} />}
        </Route >
    )
}

export default GuardAuthenticated