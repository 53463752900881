
import React, { FunctionComponent, useState } from 'react';
import "./Text.scss"

type TextProps = {
    onChange: (value: any) => void,
    value: any,
    icon?: any,
    placeholder?: string,
    type?: "text" | "password"
}

export const Text: FunctionComponent<TextProps> = ({ onChange, value, icon, placeholder, type = "text" }) => {
    const [visible, setVisible] = useState(false)
    return <div className="input input-text input-border">
        {icon && <div className="input-text__icon">{icon}</div>}
        <div className="input-text__input-container">
            <input
                className="input-text__input-container__input"
                type={type === "password" && !visible ? "password" : "text"}
                value={value}
                placeholder={placeholder}
                onChange={(event) => onChange(event.target.value)}
            />
        </div>
        {
            type === "password"
            && <div
                className="input-text__icon visible-button"
                onClick={() => setVisible(!visible)}
            >
                {
                    visible ?
                        <i className="fi-rr-eye-crossed"></i>
                        : <i className="fi-rr-eye"></i>
                }
            </div>
        }
    </div>
}