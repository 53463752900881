
import React, { FunctionComponent } from 'react';
import "./Field.scss"

type FieldProps = {
    children: any,
    label?: string,
    status?: any,
    required?: boolean
}

export const Field: FunctionComponent<FieldProps> = ({ children, label, status, required }) => {
    return <div className={`field field--${status?.type}`}>
        {!!label && <span className="field__label">{label} {required && <span className="field__label__required">*</span>}</span>}
        <div className="field__body">{children}</div>
        {!!status && <div className="field__message">
            {status?.message}
        </div>}
    </div>
}