import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../contexts/StoreContext";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../contexts/ToastContext";
import { Avatar, Button, Field, Input, Tag, View } from "../../components";
import { Color } from "../../types";
import { Loader } from "../../components/Loader/Loader";
import { LoaderContext } from "../../contexts/LoaderContext";
import firebase from "firebase";
import "./house.scss";

import moment from "moment";
import { ModalContext } from "../../contexts/ModalContext";
import { ModalPictures } from "../../modals/ModalPictures/ModalPictures";
import { SummaryElement } from "../../components/SummaryElement/SummaryElement";
import { useForm } from "../../hooks/useForm";
import { ModalConfirmation } from "../../modals/ModalConfirmation/ModalConfirmation";

function House() {
  const history = useHistory();
  const storeContext = useContext(StoreContext);
  const toastContext = useContext(ToastContext);
  const loaderContext = useContext(LoaderContext);
  const modalContext = useContext(ModalContext);
  const params = useParams() as any;

  const [assignTenant, setAssignTenant] = useState(false);

  const house = storeContext?.housesList?.find(
    (app: any) => app?.idolId === params?.idolId
  );

  useEffect(() => {
    storeContext?.setCurrentHouse(house);
  }, [house]);

  const form = useForm({
    onSubmit: async (e: any) => {
      try {
        loaderContext.open(<Loader />);
        await firebase
          .firestore()
          .collection("houses")
          .doc(params.houseId)
          .update({
            assignedTenant: e?.assignedTenant || "",
          });
        loaderContext.close();
        toastContext.open({
          color: Color.success,
          message: "Le locataire a été ajouté à ce logement avec succès.",
        });
        history.push("/tenants");
      } catch (error: any) {
        loaderContext.close();
        toastContext.open({ color: Color.danger, message: error.message });
      }
    },
    rules: {
      assignedTenant: [{ required: true, message: "Ce champ est requis" }],
    },
  });

  const getTenant = (tenantId: string) => {
    return (
      storeContext?.tenantsList?.find((t: any) => t.tenantId === tenantId) ||
      "Inconnu"
    );
  };
  const sendQuittance = () => {
    console.log("Ca va envoyer par mail la quittance");
  };

  console.log("tenants: ", storeContext?.tenantsList);
  console.log("house: ", house.assignedTenant);
  return (
    <View title="Informations Logement">
      <div className="applications__section">
        <div className="idol-header__container">
          <div className="idol-header__data">
            <h1>{house?.name}</h1>
          </div>
          {!!house?.assignedTenant && (
            <div className="idol-header__actions">
              <Button
                onClick={() =>
                  modalContext.open(
                    <ModalConfirmation
                      title={
                        "Vous allez envoyer un quittance de loyer à " +
                        getTenant(house?.assignedTenant?.[0])?.name +
                        "(" +
                        getTenant(house?.assignedTenant?.[0])?.email +
                        ")" +
                        " pour un montant de " +
                        house.rent +
                        "€ . \n Confirmez-vous ?"
                      }
                      callback={() => sendQuittance()}
                    />
                  )
                }
                color={Color.primary}
              >
                Envoyer la quittance
              </Button>
            </div>
          )}
          <div className="idol-header__actions">
            <Button
              onClick={() => history.push("/houses/edit/" + params.houseId)}
              color={Color.primary}
            >
              Modifier Le logement
            </Button>
          </div>

          <div className="idol-header__actions">
            <Button onClick={() => setAssignTenant(true)} color={Color.primary}>
              Assigner un Locataire
            </Button>
          </div>
        </div>
        {assignTenant && (
          <div>
            <div className="modal-confirmation__body">
              <form className="signin__form">
                <h1>Assignation de locataire</h1>

                <Field
                  required
                  label="Liste locataires"
                  status={form.status?.assignedTenant}
                >
                  <Input.Select
                    placeholder="Séléctionnez un locatire"
                    onChange={(value: any) =>
                      form.onChange("assignedTenant", value)
                    }
                    value={form.data.assignedTenant}
                    options={storeContext?.tenantsList.map((tenant: any) => ({
                      label: tenant?.name,
                      value: tenant?.tenantId,
                    }))}
                  />
                </Field>
              </form>
            </div>
            <div>
              <Button
                color={Color.primary}
                onClick={() => setAssignTenant(false)}
              >
                Annuler
              </Button>
              <Button
                className="margin-left"
                color={Color.warning}
                onClick={form.submit}
              >
                Confirmer
              </Button>
            </div>{" "}
          </div>
        )}
        <div>
          {!!house?.assignedTenant ? (
            <p>
              Ce bien est actuellement loué par{" "}
              <span
                className="bold pointer"
                onClick={() =>
                  history.push("/tenants/tenant/" + house?.assignedTenant)
                }
              >
                {getTenant(house?.assignedTenant?.[0])?.name}
              </span>{" "}
            </p>
          ) : (
            <p> Ce bien est actuellement libre</p>
          )}
          <h2>Récapitulatif </h2>

          <SummaryElement label="Nom" value={house?.displayName} />
          <SummaryElement label="Adresse" value={house?.address} />
          <SummaryElement label="Loyer" value={house?.rent + " €"} />
          <SummaryElement
            label="Surface habitable"
            value={house?.livableSize + " m2"}
          />
          <SummaryElement
            label="Surface terrain"
            value={!!house?.fieldSize ? house?.fieldSize : "Pas de terrain"}
          />
        </div>
      </div>
    </View>
  );
}

export default House;
