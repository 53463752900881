import moment from "moment";
import React, { FunctionComponent, useState } from "react";
import DatePicker from "react-date-picker";
import "./Date.scss";

type DateProps = {
  onChange: (value: any) => void;
  value: any;
  icon?: any;
};

export const Date: FunctionComponent<DateProps> = ({
  onChange,
  value,
  icon,
}) => {
  return (
    <div className="input input-text input-border">
      <DatePicker
        className="montesteur-datepicker"
        onChange={(date: any) => onChange(date)}
      />
      {icon && <div className="input-text__icon">{icon}</div>}
      <div className="input-text__input-container"></div>
    </div>
  );
};
