import { useContext } from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "../../contexts/ModalContext";
import "./Modal.scss";

interface ModalProps {
    title?: string,
    children?: any,
    footer?: any
}
export const Modal = ({ title, children, footer }: ModalProps) => {
    const modalContext = useContext(ModalContext)

    return ReactDOM.createPortal(
        <div className="modal">
            <div className="modal__backdrop" onClick={modalContext.close}></div>
            <div className="modal__container">
                <div className="modal__container__wrapper">
                    <div className="modal__content">
                        <div className="modal__content__header">
                            <h3 className="modal__content__header__title">{title}</h3>
                            <button className="modal__content__header__close-button" onClick={modalContext.close}>
                                <i className="fi-rr-cross-small"></i>
                            </button>
                        </div>
                        {
                            children && <div className="modal__content__child">{children}</div>
                        }
                        {
                            footer &&
                            <div className="modal__content__footer">{footer}</div>
                        }
                    </div>
                </div>
            </div>
        </div>,
        document.querySelector("#modal-root") as HTMLInputElement
    );
}