import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../contexts/StoreContext";
import { Button, Tag, View, Avatar } from "../../components";
import "./tenants.scss";
import { useHistory } from "react-router-dom";
import { Color } from "../../types";
import { ModalContext } from "../../contexts/ModalContext";
import firebase from "firebase";
import { LoaderContext } from "../../contexts/LoaderContext";
import { ToastContext } from "../../contexts/ToastContext";
import { Loader } from "../../components/Loader/Loader";
import { ModalConfirmation } from "../../modals/ModalConfirmation/ModalConfirmation";
import moment from "moment";
import { ModalPictures } from "../../modals/ModalPictures/ModalPictures";

function Tenants() {
  const storeContext = useContext(StoreContext);
  const modalContext = useContext(ModalContext);
  const toastContext = useContext(ToastContext);
  const loaderContext = useContext(LoaderContext);
  const history = useHistory();

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toastContext.open({
        color: Color.success,
        message: "Identifiant " + text + " copié!",
      });
    } catch (err: any) {
      toastContext.open({
        color: Color.danger,
        message: "Erreur: " + err.message,
      });
    }
  };

  const deleteTenant = async (tenant: any) => {
    try {
      loaderContext.open(<Loader />);
      await firebase
        .firestore()
        .collection("tenants")
        .doc(tenant?.tenantId)
        .delete();
      loaderContext.close();
      toastContext.open({
        color: Color.success,
        message: "Le locataire a été supprimé avec succès",
      });
      modalContext.close();
    } catch (error) {
      toastContext.open({
        color: Color.danger,
        message: "Erreur lors de la suppression du locataire:",
      });
      modalContext.close();
    }
  };
  console.log("TENANTS: ", storeContext?.tenantsList);
  return (
    <View title="Mes locataires">
      <div className="doctors__section">
        <div className="row ai-center jc-flex-end margin-bottom">
          <Button
            onClick={() => history.push("/tenants/create")}
            color={Color.primary}
          >
            Ajouter un locataire
          </Button>
        </div>

        <div className="col">
          {storeContext?.tenantsList
            ?.sort((a: any, b: any) => b?.creationDate - a?.creationDate)
            ?.map((el: any, index: number) => {
              return (
                <div
                  onClick={() =>
                    history.push("/tenants/tenant/" + el?.tenantId)
                  }
                  key={el?.tenantId + index}
                  className="table-body-row row ai-center flex-1"
                >
                  <div className="col ai-flex-start flex-1">
                    <div className="col ai-flex-start flex-1">
                      <p className="centers__center-row__label">{el?.name}</p>
                      <span className="centers__center-row__label__cassette-id margin-top pointer ">
                        {new Date(el?.dateOfBirth).toDateString()}
                      </span>
                    </div>
                  </div>
                  <div className="flex row ai-center">
                    <Tag
                      className="margin-right margin-left pointer"
                      color={Color.warning}
                      label="Modifier"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        history.push("/tenants/edit/" + el.tenantId);
                      }}
                    />
                    <Tag
                      className="margin-right margin-left pointer"
                      color={Color.danger}
                      label="Supprimer"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        modalContext.open(
                          <ModalConfirmation
                            title="Are you sure ?"
                            callback={() => deleteTenant(el)}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </View>
  );
}

export default Tenants;
